"use client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
export const Wwa: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="whoweare" className="bg-white">
            <div className="py-3 px-6">
                    <h5 className="text-lg lg:text-2xl font-bold py-2">{t('WWA-HEAD-TEXT')}</h5>
                </div>
            <div className="mt-2 px-1">
                <Card className="w-full rounded-lg bg-halvard border-white dark:bg-halvard dark:border-white">
                    <p className="text-md lg:text-xl font-normal text-white">{t('WWA-SPAN-ONE')}</p>
                    <p className="text-md lg:text-xl font-normal text-white">{t('WWA-SPAN-TWO')}</p>
                    <p className="text-md lg:text-xl font-normal text-white">{t('WWA-SPAN-THREE')}</p>
                </Card>
            </div>
            </section>
        </>
    );
};