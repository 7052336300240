
// @ts-nocheck
"use client";
import { FunctionComponent, useState, useEffect } from 'react';
import { Modal } from 'flowbite';
import type { ModalOptions, ModalInterface } from 'flowbite';
import type { InstanceOptions } from 'flowbite';

const $privacypolicyEl: HTMLElement = document.querySelector('#privacypolicy');

const modalOptions: ModalOptions = {
    placement: 'center',
    backdrop: 'static',
    backdropClasses:
        'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
    closable: true,
};

// instance options object
const instanceOptionspp: InstanceOptions = {
  id: 'privacypolicy',
  override: true
};



const modalpp: ModalInterface = new Modal($privacypolicyEl, modalOptions, instanceOptionspp);




import arrowImg from '/src/assets/images/arrow-square-up.svg';

const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const Foot: FunctionComponent = () => {

    const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScrollButtonVisiblity = () => {
      window.scrollY > 1500 ? setShowButton(true) : setShowButton(false);
    };
      window.addEventListener('scroll', handleScrollButtonVisiblity);
      const element = document.getElementById('scrolltotoparrow');
      if (!showButton && element !== null) {
           element.style.display = "none";
      } else {
          if(element !== null)
            element.style.display = "block";
      }
  });



  return (
    <>
      <section id="footer" className='py-2'>
        <footer className="bg-halvard rounded-none dark:bg-halvard py-3 px-2">
          <div className="flex flex-cols-2 items-center justify-between px-4 h-20">
            <div>
              <p className="text-sm text-white sm:text-start dark:text-white">© 2024 S.P.I. GMP-Consultants</p>
              <p className="text-sm text-white sm:text-start dark:text-white">All Rights Reserved</p>
            </div>
            <div>
              <div className='px-5 md:px-8'>
                <button data-modal-target="privacypolicy" data-modal-toggle="privacypolicy" className="block text-white font-medium text-sm text-center hover:text-cyan-700" type="button">
                  Privacy Policy
                </button>




  <div id="privacypolicy" data-modal-backdrop="static" tabIndex="-1" aria-hidden="true" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-halvard rounded-lg dark:bg-white">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-bg-white">
                <h3 className="text-xl font-semibold text-white dark:text-white">
                    Privacy Policy
                </h3>
                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="privacypolicy">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            <div className="p-4 md:p-5 space-y-4">
                <p className="text-center leading-relaxed text-white dark:text-white align-content-center">
                    OVERVIEW
                </p>
                <p className="text-base leading-relaxed text-white dark:text-white">
                    This Privacy Policy relates to the information collection and use practices of S.P.I. GMP-Consultants, LLC. By visiting our Website, you are agreeing to the terms of this Privacy Policy.
                </p>
                <p className="text-center leading-relaxed text-white dark:text-white align-content-center">
                    INFORMATION WE COLLECT
                </p>
                <p className="text-base leading-normal text-white dark:text-white">
                  Personal Information:
                </p>
                <p className="text-base leading-relaxed text-white dark:text-white">
                  We do not collect any personal information from you unless you voluntarily provide it to us, by sending an email from this website. We do not share this information.
                </p>
                <p className="text-base leading-normal text-white dark:text-white">
                  Usage Information:
                </p>
                <p className="text-base leading-relaxed text-white dark:text-white">
                  Like most websites, we use automatic data collection technology when you visit our site. We collect this Usage Information by using cookies. Cookies are small packets of data your browser holds about your visit.
                  We just set only one cookie, which contains the information whether the visitor has allowed the loading of the preview of YouTube videos. In this case, additional cookies are set by YouTube.
                </p>
            </div>

            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="privacypolicy" type="button" className="text-white bg-gray-400 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Ok</button>
            </div>
        </div>
    </div>
</div>
              </div>
              <div id="scrolltotoparrow" className='px-1 md:px5'>
                <button className="fixed bottom-10 md:buttom-8 right-0 z-50 cursor-pointer w-8 h-8 md:h-12 md:w-12 pr-1" onClick={handleScrollToTop}>
                  <img src={arrowImg} alt="scrollToTop"/>
                </button>
              </div>
            </div>
            </div>

      </footer>
  </section>

    </>
  );
}