"use client";
import { FunctionComponent } from 'react';
import { Navbar } from 'flowbite-react';

import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import imageUrl from '/src/assets/images/SPI_Logo_Myriad_W.png';



export const Header: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar fluid className='fixed w-full top-0 left-0 right-0 bg-halvard dark:bg-halvard dark:border-halvard px-2 sm:px-3 md:px-4 lg:px-5 xl:px-6 2xl:px-8 h-18 md:h-24 lg:h-28 xl:h-32'>
        <Navbar.Brand as={Link} href='/'>
          <img src={imageUrl} className='h-14 md:h-20 lg:h-24 xl:h-28' alt='s.p.i. gmp consultants'/>

        </Navbar.Brand>
        <Navbar.Toggle className='text-white'/>
          <Navbar.Collapse>
            <Navbar.Link className='text-sm md:text-lg lg:text-xl text-white' href={t("servicesSection")} >{t("MENU-Services")}</Navbar.Link>
            <Navbar.Link className='text-sm md:text-lg lg:text-xl text-white' href={t("wwaSection")} > {t("MENU-WhoWeAre")}</Navbar.Link>
            <Navbar.Link className='text-sm md:text-lg lg:text-xl text-white' href={t("referencesSection")} >{t("MENU-References")}</Navbar.Link>
            <Navbar.Link className='text-sm md:text-lg lg:text-xl text-white' href={t("contactSection")} >{t("MENU-Contact")}</Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}