"use client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
import apriligen from '/src/assets/images/apriligen_dark.png';
import arensia from '/src/assets/images/Arensia.png';
import vetmedia from '/src/assets/images/BoehringerIngelheim-dark.svg';
import celgen from '/src/assets/images/Celgene.svg';
import editas from '/src/assets/images/logo-editas-white_112c6be8.svg';
import medac from '/src/assets/images/medac_logo_2.svg';
import miltenyi from '/src/assets/images/miltenyi-biotec-logo.svg';
import nmdp from '/src/assets/images/logo-nmdp-biotherapies-full-color.svg';
import novartis from '/src/assets/images/Novartis.svg';
import sandoz from '/src/assets/images/Sandoz-Logo-Blue-Hexal-Partner_h100.png';
import terumo from '/src/assets/images/terumo.svg';
export const References: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="references" className="bg-white rounded-lg">
                <div className="py-3 px-6">
                    <h5 className="text-lg lg:text-2xl font-bold text-zinc-800 py-2">{t('REFERENCES-HEAD-TEXT')}</h5>
                </div>
                <div className="px-1 py-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 bg-halvard">
                    <div className="px-1">
                        <Card className="bg-zinc-400 rounded-lg w-full px-2">
                            <img className="h-12" src={apriligen}></img>
                            <p className="self-center">Apriligen</p>
                        </Card>
                    </div>
                    <div className="px-1">
                        <Card className="bg-zinc-400 rounded-lg w-full px-2">
                            <img className="h-12" src={arensia}></img>
                            <p className="self-center">Arensia</p>
                        </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className=" h-12" src={vetmedia}></img>
                        <p className="self-center">Boehringer-Ingelheim Vetmedica</p>
                    </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className=" h-12" src={celgen}></img>
                        <p className="self-center">Celgene</p>
                    </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className=" h-12" src={editas}></img>
                        <p className="self-center">Editas</p>
                    </Card>
                    </div>
                    <div className="px-1">
                        <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className="  h-12" src={medac}></img>
                        <p className="self-center">Medac</p>
                        </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                            <img className="  h-12" src={miltenyi}></img>
                            <p className="self-center">Miltenyi Biotec</p>
                    </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className="  h-12" src={nmdp}></img>
                        <p className="self-center">NMDP BioTherapies</p>
                    </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className="  h-12" src={novartis}></img>
                        <p className="self-center">Novartis</p>
                    </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className="  h-12" src={sandoz}></img>
                        <p className="self-center">Sandoz-Hexal (a Novartis company)</p>
                    </Card>
                    </div>
                    <div className="px-1">
                    <Card className="bg-zinc-400 rounded-lg w-full px-2">
                        <img className="  h-12" src={terumo}></img>
                        <p className="self-center">Terumo BCT</p>
                    </Card>
                    </div>
                </div>
            </section>
        </>
    );
};