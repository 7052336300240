"use client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
import halvardImg from '/src/assets/images/HalvardB.jpg';

export const Contact: FunctionComponent = () => {

    const { t } = useTranslation();
    return (
        <>
            <section id="contact" className="bg-white">
            <div className="py-3 px-5">
                <h5 className="text-lg lg:text-2xl font-bold py-2">{t('CONTACT-HEAD-TEXT')}</h5>
            </div>
            <div className="flex px-1">
               <Card className="w-full lg:w-3/4 rounded-r-none bg-halvard border-l-white border-r-halvard dark:bg-halvard dark:border-halvard">
                    <p className="text-2xl font-bold text-white">{t('CONTACT-COMPANY-NAME')}</p>
                    <p className="text-md lg:text-xl font-bold text-white">{t('CONTACT-COMPANY-UBI')}</p>
                    <span className="text-md lg:text-xl font-bold text-white">{t('CONTACT-ADRESS-PHYSICAL-KEY')}<span className="text-md lg:text-xl font-normal text-white">{t('CONTACT-ADRESS-PHYSICAL-VALUE')}</span></span>
                    <span className="text-md lg:text-xl font-bold text-white">{t('CONTACT-ADRESS-MAILING-KEY')}<span className="text-md lg:text-xl font-normal text-white">{t('CONTACT-ADRESS-MAILING-VALUE')}</span></span>
                    <span className="text-md lg:text-xl font-bold text-white">{t('CONTACT-ADRESS-EMAIL-KEY')}<span className="text-md lg:text-xl font-normal text-white"><a href={t('CONTACT-ADRESS-MAILTO-VALUE')}>{t('CONTACT-ADRESS-EMAIL-VALUE')}</a></span></span>
                </Card>
                <Card className="w-full lg:w-1/4 bg-halvard rounded-l-none border-r-white border-l-halvard dark:bg-halvard dark:border-halvard">
                    <img className="h-56 w-36 p-0" src={halvardImg} alt="" />
                </Card>
                </div>
        </section>
        </>
    );
};