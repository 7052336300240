"use client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";


export const Services: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="services" className="bg-white">
                <div className="py-3 px-6">
                    <h5 className="text-lg lg:text-2xl font-bold py-2">{t('SERVICES-HEAD-TEXT')}</h5>
                </div>

                    <div className="px-1">
                        <Card className="bg-halvard rounded-lg border-white dark:bg-halvard dark:border-white">
                            <div className="grid grid-cols-1 md:grid-cols-2 divide-x-none md:divide-x">
                                <div className="pr-5 basis-3/5">
                                    <h4 className="text-lg lg:text-2xl font-bold text-white">{t('SERVICES-SOHO-HEAD')}</h4>
                                    <hr className="h-px my-8 bg-zinc-400 border-0 dark:bg-zinc-400"></hr>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-ONE')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-TWO')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-THREE')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-FOUR')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-FIVE')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-SEVEN')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-EIGHT')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-SOHO-BODY-NINE')}</p>
                                </div>
                                <div className="pt-5 md:pt-0 pl-0 md:pl-5">
                                    <h4 className="text-lg lg:text-2xl font-bold text-white">{t('SERVICES-INVESTORS-HEAD')}</h4>
                                    <hr className="h-px mb-8 mt-4 md:mt-8 bg-zinc-400 border-0 dark:bg-zinc-400"></hr>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-INVESTORS-BODY-ONE')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-INVESTORS-BODY-TWO')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-INVESTORS-BODY-THREE')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-INVESTORS-BODY-FOUR')}</p>
                                    <p className="text-md lg:text-xl font-normal text-white">{t('SERVICES-INVESTORS-BODY-FIVE')}</p>
                                </div>
                            </div>
                        </Card>
                    </div>


            </section>
        </>
    );
};