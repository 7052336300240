"use client";
import ReactDOM from 'react-dom/client'

import './assets/fonts/roboto/RobotoCondensed-Thin.ttf'
import './assets/fonts/roboto/RobotoCondensed-ExtraLight.ttf'
import './assets/fonts/roboto/RobotoCondensed-Light.ttf'
import './assets/fonts/roboto/RobotoCondensed-Regular.ttf'
import './assets/fonts/roboto/RobotoCondensed-Medium.ttf'
import './assets/fonts/roboto/RobotoCondensed-SemiBold.ttf'
import './assets/fonts/roboto/RobotoCondensed-Bold.ttf'
import './assets/fonts/roboto/RobotoCondensed-Black.ttf'
import './assets/images/genetic-data.svg'

import './index.css'
import { Header } from './components/Header'
import  App  from './App'

import React, { Suspense } from 'react'
import { Home } from './components/Home';
import { Wwa } from './components/Wwa';
import { Services } from './components/Services'
import { Contact } from './components/Contact';
import { References } from './components/References';
import { Foot } from './components/Foot';
import { Media } from './components/Media';




ReactDOM.createRoot(document.getElementById('header')!).render(
    <React.StrictMode>
        <Header />
    </React.StrictMode>
)

ReactDOM.createRoot(document.getElementById('app')!).render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
        <App />
    </Suspense>
    </React.StrictMode>
)

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <Home />
        <Services />
        <Wwa />
        <Media />
        <References />
        <Contact />
        <Foot />
      </Suspense>
      </React.StrictMode>
  )



