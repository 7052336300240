import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEn from '../locales/EN/translation.json';



const resources = {
    EN: {
        translation: translationEn
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'EN',
    debug: true,
    fallbackLng: 'EN',
    interpolation: {
        escapeValue: false
    },
    ns: 'translation',
    defaultNS: 'translation'
});

export default i18n;