"use client";

import { initReactI18next, useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import { isMobile } from 'react-device-detect';
import i18n from './i18n';


import './App.css'


initReactI18next.init(i18n);
function refreshPage() {
    window.location.reload();
  }

function App() {
  const { t } = useTranslation();
  if (isMobile) {
  return (
    <>
      <CookieConsent enableDeclineButton flipButtons
  location="bottom"
  buttonText="I Accept"
  cookieName="spigmpcoockie"
  style={{ background: "#ffffff", height: "20vh", alignItems: "center" }}
  buttonStyle={{ background: "#001a28", color: "#ffffff", fontFamily: "roboto", fontWeight: "400" }}
  declineButtonText="I Decline"
  declineButtonStyle={{ background: "#001a28", color: "#ffffff", fontFamily: "roboto", fontWeight: "400" }}
        expires={150}
        onAccept={refreshPage}
>
        <span style={{ color: "#000000", fontSize: "13px", fontFamily: "roboto", fontWeight: "400", paddingBottom: "1rem" }}>{t("COOKIE-TEXT")}</span>
</CookieConsent>
    </>
  )
  } else {
  return (
    <>
      <CookieConsent enableDeclineButton flipButtons
  location="bottom"
  buttonText="I Accept"
  cookieName="spigmpcoockie"
  style={{ background: "#ffffff", height: "10vh", alignItems: "center", paddingLeft: "1rem", paddingRight: "1rem" }}
  buttonStyle={{ background: "#001a28", color: "#ffffff" , fontFamily: "roboto", fontWeight: "500"}}
  declineButtonText="I Decline"
  declineButtonStyle={{ background: "#001a28", color: "#ffffff", fontFamily: "roboto", fontWeight: "500" }}
        expires={150}
        onAccept={refreshPage}
>
  <span style={{ color: "#000000", fontSize: "20px", fontFamily: "roboto", fontWeight: "500" }}>{t("COOKIE-TEXT")}</span>
</CookieConsent>
    </>
  )
  }


}

export default App
