"use client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
import './home.module.css';

export const Home: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <section id="home">

                <div className="bg-no-repeat bg-[url('/src/assets/images/sangharsh-lohakare-Iy7QyzOs1bo-unsplash.jpg')] bg-blend-multiply w-full text-center mt-16 sm:mt-24 md:24 lg:mt-28 xl:mt-28 h-112 md:h-120 lg:h-128 xl:h-136">
                        <h1 className="mb-8 pt-28 text-xl lg:text-3xl font-extrabold leading-none text-white">{t("HOME-HEAD-TEXT")}</h1>
                        <p className="mb-4 text-2xl lg:text-4xl font-bold text-white sm:px-16 lg:px-48">{t("HOME-HEAD-DESC-SHORT")}</p>
                    </div>

                <div className="flex mt-2 px-1">


               <Card className="w-full rounded-lg bg-halvard border-white dark:bg-halvard dark:border-halvard">
                <h4 className="text-lg lg:text-2xl font-bold text-white">{t('HOME-HEAD-TEXT')}</h4>
                <p className="text-md lg:text-xl font-normal text-white">{t('HOME-HEAD-DESC-LONG')}</p>
               </Card>

                </div>
            </section>
        </>
    );
};