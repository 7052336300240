// @ts-nocheck
"use client";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";


export const Media: FunctionComponent = () => {
    console.log(getCookieConsentValue("spigmpcoockie"));
    const { t } = useTranslation();
    const cookievalue = getCookieConsentValue("spigmpcoockie");
    if (cookievalue == undefined || cookievalue == "false") {
        return (
            <>
            <section id="media" className="bg-white">
                <div className="py-3 px-6">
                    <h5 className="text-lg lg:text-2xl font-bold py-2">{t('MEDIA-HEAD-TEXT')}</h5>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 px-1">
                    <Card className="bg-halvard rounded-lg border-white dark:bg-halvard dark:border-white">
                            <span><a href="https://www.youtube.com/embed/L_5gRhMFTgE?autoplay=1" className="text-white font-medium" target="_blank">CARAMBA interview: manufactoring process (EN) on youtube</a>
                            </span>
                    </Card>
                    <Card className="bg-halvard rounded-lg border-white dark:bg-halvard dark:border-white">
                        <span><a href="https://www.youtube.com/embed/qrlT6wG8oIA?autoplay=1" className="text-white font-medium" target="_blank">Mesenchymal stem cells (EN) on youtube</a></span>
                    </Card>
                    <Card className="bg-halvard rounded-lg border-white dark:bg-halvard dark:border-white">
                        <span><a href="https://www.youtube.com/embed/SrimnbpdgiQ?autoplay=1" className="text-white font-medium"  target="_blank">Second life thanks to stem cell donation (DE) on youtube</a></span>
                    </Card>

                </div>
            </section>
            </>
        );
    }
    return (
        <>
            <section id="media" className="bg-white">
                <div className="py-3 px-6">
                    <h5 className="text-lg lg:text-2xl font-bold py-2">{t('MEDIA-HEAD-TEXT')}</h5>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 px-1">
                    <Card className="bg-halvard rounded-lg border-white dark:bg-halvard dark:border-white">
                            <iframe
                                className='h-64 w-full rounded-lg'
                                src="https://www.youtube-nocookie.com/embed/L_5gRhMFTgE?autoplay=0&modestbranding=1"
                                width="100%"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                            </iframe>
                    </Card>
                    <Card className="bg-halvard rounded-lg border-white dark:bg-halvard dark:border-white">
                            <iframe
                                className='h-64 w-full rounded-lg'
                                src="https://www.youtube-nocookie.com/embed/qrlT6wG8oIA?autoplay=0&modestbranding=1"
                                width="100%"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                            </iframe>
                    </Card>
                    <Card className="bg-halvard rounded-lg border-white dark:bg-halvard dark:border-white">
                            <iframe
                                className='h-64 w-full rounded-lg'
                                src="https://www.youtube-nocookie.com/embed/SrimnbpdgiQ?autoplay=0&modestbranding=1"
                                width="100%"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>
                            </iframe>
                    </Card>

                </div>
            </section>
        </>
    );
};